/* Contact Section */
.contact-section {
    position: relative;
    padding: 120px 40px;
    background-color: #0a0a0a;
    overflow: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1.2s ease, transform 1.2s ease;
}

.contact-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.contact-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.contact-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contact-particle {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    animation: float-contact-particle 20s infinite linear;
}

.contact-particle:nth-child(odd) {
    width: 3px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.3);
}

.contact-particle:nth-child(3n) {
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.4);
    animation-duration: 30s;
}

.contact-particle:nth-child(1) { top: 15%; left: 10%; animation-delay: 0s; }
.contact-particle:nth-child(2) { top: 25%; left: 90%; animation-delay: 2s; }
.contact-particle:nth-child(3) { top: 40%; left: 30%; animation-delay: 4s; }
.contact-particle:nth-child(4) { top: 65%; left: 70%; animation-delay: 6s; }
.contact-particle:nth-child(5) { top: 80%; left: 20%; animation-delay: 8s; }
.contact-particle:nth-child(6) { top: 35%; left: 80%; animation-delay: 10s; }
.contact-particle:nth-child(7) { top: 75%; left: 40%; animation-delay: 12s; }
.contact-particle:nth-child(8) { top: 10%; left: 60%; animation-delay: 14s; }
.contact-particle:nth-child(9) { top: 45%; left: 5%; animation-delay: 16s; }
.contact-particle:nth-child(10) { top: 90%; left: 85%; animation-delay: 18s; }

@keyframes float-contact-particle {
    0% {
        transform: translateY(0) translateX(0);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform: translateY(-100px) translateX(100px);
        opacity: 0;
    }
}

.contact-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 60px;
}

.contact-content {
    display: flex;
    flex-direction: column;
}

.contact-header {
    margin-bottom: 50px;
}

.contact-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.title-underline {
    height: 3px;
    width: 80px;
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%);
    margin-bottom: 20px;
}

.contact-subtitle {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
    max-width: 80%;
}

.contact-form-wrapper {
    background: rgba(30, 30, 40, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 40px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.success-message {
    text-align: center;
    padding: 40px 20px;
    animation: fade-in 0.5s ease;
}

.success-icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px;
    border-radius: 50%;
    background-color: rgba(50, 200, 100, 0.15);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-icon::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 15px;
    border-left: 3px solid rgba(50, 200, 100, 0.9);
    border-bottom: 3px solid rgba(50, 200, 100, 0.9);
    transform: rotate(-45deg) translate(3px, -5px);
}

.success-message h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
}

.success-message p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.form-group {
    position: relative;
}

.form-label {
    position: absolute;
    top: 16px;
    left: 16px;
    color: rgba(255, 255, 255, 0.6);
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 0.95rem;
}

.form-group.focused .form-label, 
.form-group.has-value .form-label {
    top: -10px;
    left: 12px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(30, 30, 40, 0.9);
    padding: 0 5px;
}

.form-input, 
.form-textarea {
    width: 100%;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease;
    outline: none;
}

.form-textarea {
    min-height: 150px;
    resize: vertical;
}

.input-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.form-group.focused .input-border {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
}

.char-counter {
    display: flex;
    align-items: center;
    margin-top: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.form-group.focused .char-counter, 
.form-group.has-value .char-counter {
    opacity: 1;
}

.counter-text {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 10px;
}

.counter-bar {
    flex: 1;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1px;
    overflow: hidden;
}

.counter-progress {
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(200, 200, 255, 0.7));
    transition: width 0.3s ease;
}

.error-message {
    color: rgba(255, 100, 100, 0.9);
    font-size: 0.9rem;
    padding: 5px 0;
    animation: shake 0.4s ease;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}

.submit-button {
    padding: 16px 36px;
    background: linear-gradient(90deg, #fff, #e0e0e0);
    color: #000;
    font-weight: 600;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.button-arrow {
    transition: transform 0.3s ease;
}

.submit-button:hover .button-arrow {
    transform: translateX(5px);
}

.submit-button.submitting {
    opacity: 0.8;
    cursor: not-allowed;
}

.button-loader {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.info-card {
    background: rgba(30, 30, 40, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.info-card:hover {
    transform: translateY(-5px);
    background: rgba(40, 40, 50, 0.4);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.info-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.08);
    color: rgba(255, 255, 255, 0.9);
}

.info-icon svg {
    width: 24px;
    height: 24px;
}

.info-card h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.info-card p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.95rem;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .contact-container {
        gap: 40px;
    }
    
    .contact-subtitle {
        max-width: 100%;
    }
}

@media (max-width: 992px) {
    .contact-container {
        grid-template-columns: 1fr;
    }
    
    .contact-info {
        flex-direction: row;
        gap: 20px;
    }
    
    .info-card {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .contact-section {
        padding: 80px 20px;
    }
    
    .contact-title {
        font-size: 2.5rem;
    }
    
    .form-row {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    
    .contact-form-wrapper {
        padding: 30px 20px;
    }
    
    .contact-info {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 2rem;
    }
    
    .contact-form {
        gap: 20px;
    }
}
