.footer {
    background-color: #0a0a0a;
    color: #f8f8f8;
    padding: 80px 40px 30px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    position: relative;
    overflow: hidden;
}

.footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%);
}

.footer-top {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 80px;
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 60px;
}

.footer-logo-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.footer-logo {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -0.03em;
    margin: 0;
    background: linear-gradient(90deg, #fff, #999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity 0.3s ease;
}

.footer-logo:hover {
    opacity: 0.8;
}

.footer-tagline {
    font-size: 1rem;
    font-weight: 400;
    color: #999;
    margin: 0;
    max-width: 80%;
}

.footer-newsletter {
    margin-top: 20px;
}

.footer-newsletter h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: #e0e0e0;
}

.newsletter-form {
    display: flex;
    gap: 10px;
}

.newsletter-form input {
    flex: 1;
    padding: 14px 18px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #fff;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.newsletter-form input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.08);
}

.btn-subscribe {
    padding: 14px 24px;
    background-color: #fff;
    color: #000;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
}

.btn-subscribe:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
}

.footer-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.footer-nav-column {
    display: flex;
    flex-direction: column;
}

.footer-heading {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 24px 0;
    color: #fff;
    position: relative;
}

.footer-heading::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
}

.footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-link {
    color: #999;
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.footer-link::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: width 0.3s ease;
}

.footer-link:hover {
    color: #fff;
}

.footer-link:hover::after {
    width: 100%;
}

.footer-divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    max-width: 1400px;
    margin: 0 auto;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 30px auto 0;
    color: #666;
    font-size: 0.85rem;
}

.copyright {
    font-weight: 400;
}

.footer-social {
    display: flex;
    gap: 20px;
}

.social-link {
    color: #999;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
}

.social-icon {
    font-size: 0.9rem;
}

.social-link:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.footer-email-link {
    color: #999;
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.9rem;
}

.footer-email-link:hover {
    color: #fff;
}

@media (max-width: 1024px) {
    .footer {
        padding: 60px 30px 30px;
    }
    
    .footer-top {
        grid-template-columns: 1fr;
        gap: 50px;
    }
    
    .footer-logo-section {
        max-width: 450px;
    }
    
    .footer-nav {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 50px 20px 20px;
    }
    
    .footer-nav {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 20px;
    }
    
    .footer-bottom {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }
    
    .newsletter-form {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .footer-nav {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

