.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px 40px;
    z-index: 1000;
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    background-color: rgba(10, 10, 10, 0.95);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: -1;
    opacity: 1;
}

.header::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,0.3) 50%, 
        rgba(255,255,255,0) 100%);
    opacity: 0.7;
}

.header-scrolled {
    padding: 15px 40px;
    background-color: rgba(10, 10, 10, 0.98);
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.header-scrolled::before {
    opacity: 1;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

/* Add subtle floating animation to the header elements */
@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0px);
    }
}

.logo-container {
    z-index: 5;
    animation: float 6s ease-in-out infinite;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
    background: linear-gradient(90deg, #fff 0%, #c9c9c9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}

.logo:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.main-nav {
    display: flex;
    align-items: center;
}

.nav-list {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    position: relative;
}

.nav-link {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
    padding: 5px 0;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.nav-link::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #fff, transparent);
    transition: width 0.3s ease;
}

.nav-link:hover {
    color: #fff;
}

.nav-link:hover::after,
.nav-link.active::after {
    width: 100%;
}

.nav-link.active {
    color: #fff;
}

.cta-container {
    margin-left: 40px;
}

.cta-button {
    display: inline-block;
    padding: 12px 24px;
    background: #ffffff;
    color: #000;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cta-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: -1;
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.cta-button:hover::before {
    transform: translateX(0);
}

/* Mobile Menu Toggle */
.menu-toggle {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 25px;
    position: relative;
    z-index: 1001;
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }
}

.menu-line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: absolute;
}

.menu-line:nth-child(1) {
    top: 0;
}

.menu-line:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
}

.menu-line:nth-child(3) {
    bottom: 0;
}

/* Mobile Menu */
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

.mobile-menu-overlay.open {
    opacity: 1;
    visibility: visible;
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 85%;
    max-width: 400px;
    height: 100vh;
    background-color: #0a0a0a;
    padding: 25px;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.mobile-menu.open {
    transform: translateX(0);
}

.mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.mobile-menu-header .logo {
    font-size: 1.8rem;
}

.close-button {
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-icon {
    position: relative;
    width: 100%;
    height: 100%;
}

.close-icon::before,
.close-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
}

.close-icon::before {
    transform: rotate(45deg);
}

.close-icon::after {
    transform: rotate(-45deg);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.mobile-nav {
    margin-top: 20px;
    flex: 1;
}

.mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav-item {
    margin-bottom: 25px;
}

.mobile-nav-link {
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.3s ease;
    letter-spacing: -0.02em;
    position: relative;
    padding-bottom: 5px;
}

.mobile-nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #fff, transparent);
    transition: width 0.3s ease;
}

.mobile-nav-link:hover,
.mobile-nav-link.active {
    color: #fff;
}

.mobile-nav-link:hover::after,
.mobile-nav-link.active::after {
    width: 50px;
}

.mobile-cta {
    margin-top: auto;
    padding: 30px 0;
}

.mobile-cta-button {
    display: block;
    width: 100%;
    padding: 16px 24px;
    background: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s ease;
}

.mobile-cta-button:hover {
    background: rgba(255, 255, 255, 0.9);
}

.mobile-social {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.mobile-social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
}

.mobile-social-link:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.mobile-social-icon {
    font-size: 1rem;
}

/* Media Queries */
@media (max-width: 1024px) {
    .header {
        padding: 20px 30px;
    }
    
    .header-scrolled {
        padding: 15px 30px;
    }
    
    .nav-list {
        gap: 25px;
    }
}

@media (max-width: 768px) {
    .header-container {
        flex-wrap: wrap;
    }
    
    .main-nav,
    .cta-container {
        display: none;
    }
    
    .menu-toggle {
        display: block;
    }
    
    .header {
        padding: 20px;
    }
    
    .header-scrolled {
        padding: 15px 20px;
    }

    /* Hide menu toggle when mobile menu is open */
    .mobile-menu-overlay.open + .menu-toggle,
    .menu-toggle.open {
        display: none;
    }
}

@media (max-width: 640px) { 
    .hero-text h2 {
        font-size: 3rem;
    }
    
    .hero-text p {
        font-size: 1rem; 
    }

    .usp-item h3 {
        font-size: 1.5rem; 
    }
    
    .usp-item p {
        font-size: 0.9rem;
    }
}

.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header-accent {
    position: absolute;
    top: -50%;
    right: -10%;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 70%);
    filter: blur(40px);
    opacity: 0.6;
    animation: pulse 15s infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.6;
    }
    100% {
        transform: scale(1);
        opacity: 0.4;
    }
}