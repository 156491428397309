@media (max-width: 640px) {
    .history-section h2 {
        font-size: 2.5rem;
    }
    
    .timeline-item span {
        font-size: 1.5rem;
    }
    
    .timeline-content h3 {
        font-size: 1.2rem;
    }
    
    .timeline-content p {
        font-size: 0.9rem;
    }

    .project-section h2 {
        font-size: 2.5rem;
    }

    .project-item h3 {
        font-size: 1.2rem;
    }
    
    .project-item p {
        font-size: 0.9rem;
    }
}

/* Timeline Section */
.history-section {
    position: relative;
    padding: 120px 40px 80px;
    background: linear-gradient(180deg, #0a0a0a 0%, #111121 100%);
    overflow: hidden;
    color: #fff;
}

.history-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 70%);
    pointer-events: none;
}

.section-title {
    font-size: 3.5rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.section-title-underline {
    height: 4px;
    width: 100px;
    background: linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.1));
    margin: 20px auto 60px;
    position: relative;
    transform: scaleX(0);
    animation: scaleIn 1s cubic-bezier(0.16, 1, 0.3, 1) 0.5s forwards;
}

.section-title-underline::before,
.section-title-underline::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    transform: translateY(-50%);
}

.section-title-underline::before {
    left: -4px;
}

.section-title-underline::after {
    right: -4px;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px;
    background: linear-gradient(180deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,0.2) 10%,
        rgba(255,255,255,0.2) 90%,
        rgba(255,255,255,0) 100%
    );
    transform: translateX(-50%);
}

.timeline-item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-left: 30px;
    margin-bottom: 80px;
    width: 50%;
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.timeline-item:nth-child(even) {
    margin-left: auto;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 30px;
    transform: translateX(100px);
}

.timeline-item.visible {
    opacity: 1;
    transform: translateX(0);
}

.timeline-year {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.8rem;
    font-weight: 700;
    color: #fff;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 20px;
    border-radius: 30px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 2;
}

.timeline-content {
    position: relative;
    background: rgba(255, 255, 255, 0.03);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
    width: calc(100% - 60px);
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.timeline-content::before {
    content: '';
    position: absolute;
    top: 20px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.03);
    transform: rotate(45deg);
    box-shadow: 5px -5px 10px rgba(0,0,0,0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.timeline-item:nth-child(even) .timeline-content::before {
    right: auto;
    left: -15px;
    box-shadow: -5px -5px 10px rgba(0,0,0,0.05);
}

.timeline-content::after {
    content: '';
    position: absolute;
    top: 20px;
    right: -50px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.05);
    transform: scale(0);
    animation: popIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: calc(var(--delay) * 0.2s);
}

.timeline-item:nth-child(even) .timeline-content::after {
    right: auto;
    left: -50px;
}

.timeline-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #fff;
    position: relative;
    padding-bottom: 15px;
}

.timeline-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.1));
    transform: scaleX(0);
    transform-origin: left;
    animation: scaleIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: calc(var(--delay) * 0.3s);
}

.timeline-description {
    font-size: 1rem;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.7);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: calc(var(--delay) * 0.4s);
}

.timeline-item:hover .timeline-content {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.3);
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.2);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes popIn {
    from {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    to {
        transform: scale(1);
    }
}

/* Add floating particles */
.timeline-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.timeline-particle {
    position: absolute;
    width: 6px;
    height: 6px;
    background: rgba(255,255,255,0.1);
    border-radius: 50%;
    pointer-events: none;
}

.timeline-particle:nth-child(3n) {
    width: 4px;
    height: 4px;
    background: rgba(255,255,255,0.15);
}

.timeline-particle:nth-child(4n) {
    width: 8px;
    height: 8px;
    background: rgba(255,255,255,0.05);
}

@keyframes floatParticle {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translate(var(--moveX), var(--moveY));
        opacity: 0;
    }
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .timeline::before {
        left: 30px;
    }

    .timeline-item {
        width: 100%;
        padding-left: 80px;
    }

    .timeline-item:nth-child(even) {
        padding-left: 80px;
        padding-right: 0;
    }

    .timeline-year {
        left: 30px;
        transform: translateX(-50%);
    }

    .timeline-content::after {
        left: -50px;
        right: auto;
    }

    .timeline-item:nth-child(even) .timeline-content::after {
        left: -50px;
    }

    .timeline-content::before {
        left: -15px;
        right: auto;
        box-shadow: -5px -5px 10px rgba(0,0,0,0.05);
    }

    .timeline-item:nth-child(even) .timeline-content::before {
        left: -15px;
    }
}

@media (max-width: 576px) {
    .section-title {
        font-size: 2.5rem;
    }

    .timeline-year {
        font-size: 1.4rem;
        padding: 8px 16px;
    }

    .timeline-content {
        padding: 20px;
    }

    .timeline-title {
        font-size: 1.3rem;
    }

    .timeline-description {
        font-size: 0.9rem;
    }
}

/* Projects Section */
.project-section {
    position: relative;
    padding: 120px 40px;
    background: linear-gradient(180deg, #111121 0%, #0a0a0a 100%);
    overflow: hidden;
}

.project-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 70%);
    pointer-events: none;
}

.project-header {
    text-align: center;
    margin-bottom: 80px;
}

.project-section .section-title {
    color: #fff;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.project-section .section-title-underline {
    background: linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.1));
}

.project-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.project-item {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 0.8s ease, transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    transition-delay: calc(var(--delay, 0) * 0.2s);
}

.project-item:nth-child(1) {
    --delay: 1;
}

.project-item:nth-child(2) {
    --delay: 2;
}

.project-item:nth-child(3) {
    --delay: 3;
}

.project-item.visible {
    opacity: 1;
    transform: translateX(0);
}

.project-content {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.project-item:hover .project-content {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.project-image {
    margin-bottom: 25px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.project-image-content {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    transition: transform 0.5s ease;
}

.project-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.8));
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.project-content:hover .project-image-overlay {
    opacity: 1;
}

.project-content:hover .project-image-content {
    transform: scale(1.05);
}

.project-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 20px;
    padding: 20px;
    text-align: center;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 5px;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stat-label {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    text-transform: capitalize;
}

.project-technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
}

.tech-tag {
    padding: 4px 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.tech-tag:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.project-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.project-description {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
    flex-grow: 1;
}

.project-link {
    display: inline-flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    margin-top: auto;
    transition: all 0.3s ease;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.project-link:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.project-link:hover .arrow-icon {
    transform: translateX(5px);
}

/* Responsive styles */
@media (max-width: 1200px) {
    .project-grid {
        gap: 30px;
    }
}

@media (max-width: 992px) {
    .section-title {
        font-size: 2.5rem;
    }
    
    .project-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .history-section,
    .project-section {
        padding: 80px 20px;
    }
    
    .section-title {
        font-size: 2.2rem;
        margin-bottom: 40px;
    }
    
    .timeline-year {
        font-size: 1.5rem;
        min-width: 60px;
    }
    
    .timeline-content {
        padding: 20px;
    }
    
    .timeline-title {
        font-size: 1.2rem;
    }
    
    .project-grid {
        grid-template-columns: 1fr;
        gap: 30px;
    }
    
    .project-item:nth-child(2) {
        --delay: 1.5;
    }
    
    .project-item:nth-child(3) {
        --delay: 2;
    }

    .project-stats {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .stat-value {
        font-size: 1.2rem;
    }

    .stat-label {
        font-size: 0.7rem;
    }

    .tech-tag {
        font-size: 0.75rem;
        padding: 3px 10px;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }
    
    .timeline-year {
        font-size: 1.3rem;
    }
    
    .timeline-content {
        padding: 15px;
    }
    
    .project-content {
        padding: 20px;
    }
    
    .project-title {
        font-size: 1.3rem;
    }

    .project-stats {
        grid-template-columns: repeat(2, 1fr);
    }
}
