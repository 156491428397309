@import 'tailwindcss/tailwind.css';

/* Page transition effects */
.page-transition .content-container {
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Page exit transition effects */
.page-exit .content-container {
  animation: fadeOut 0.3s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Ensure content container has proper styling */
.content-container {
  min-height: calc(100vh - 70px); /* Adjust based on your header height */
  position: relative;
  will-change: opacity, transform;
}
