/* Services Page */
.services-page {
    min-height: 100vh;
    background: linear-gradient(180deg, #0a0a0a 0%, #111121 100%);
    color: #fff;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    max-width: 100vw;
}

.services-section {
    position: relative;
    padding: 120px 40px 80px;
    overflow: visible;
    z-index: 1;
    min-height: 100vh;
}

.services-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 70%);
    pointer-events: none;
    transform: translateZ(-2px) scale(3);
    z-index: -1;
}

/* Header Styles */
.services-header {
    text-align: center;
    margin-bottom: 80px;
    transform: translateZ(0);
    position: relative;
}

.section-title {
    font-size: 3.5rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    opacity: 0;
    transform: translateY(30px) translateZ(50px);
    animation: fadeInUp 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    text-shadow: 0 2px 10px rgba(0,0,0,0.3);
}

.section-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    max-width: 600px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px) translateZ(30px);
    animation: fadeInUp 1s cubic-bezier(0.16, 1, 0.3, 1) 0.3s forwards;
}

.section-title-underline {
    height: 4px;
    width: 100px;
    background: linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.1));
    margin: 20px auto 60px;
    position: relative;
    transform: scaleX(0);
    animation: scaleIn 1s cubic-bezier(0.16, 1, 0.3, 1) 0.5s forwards;
}

.section-title-underline::before,
.section-title-underline::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    transform: translateY(-50%);
}

.section-title-underline::before {
    left: -4px;
}

.section-title-underline::after {
    right: -4px;
}

/* Services Grid */
.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 20px;
}

.service-item {
    position: relative;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    min-height: 300px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 0.5s ease forwards;
    will-change: transform, opacity;
}

.service-item:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.service-item.active {
    transform: scale(1.02);
    z-index: 10;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
}

.service-content {
    padding: 30px;
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease;
}

.service-item.active .service-content {
    opacity: 0;
}

.service-icon {
    font-size: 2.5rem;
    margin-bottom: 20px;
    opacity: 0.9;
}

.service-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-description {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
}

.service-features {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.feature-tag {
    padding: 4px 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
    transition: transform 0.2s ease, background-color 0.2s ease;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.feature-tag:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.service-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    pointer-events: none;
}

.service-item.active .service-overlay {
    opacity: 1;
    pointer-events: auto;
}

.service-details {
    text-align: center;
    transform: translateY(20px);
    opacity: 0;
    transition: all 0.3s ease;
}

.service-item.active .service-details {
    transform: translateY(0);
    opacity: 1;
}

.service-details h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
}

.service-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.service-details li {
    margin: 10px 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
}

/* Floating Particles */
.services-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    transform: translateZ(-3px) scale(4);
    z-index: 0;
}

.service-particle {
    position: absolute;
    width: 6px;
    height: 6px;
    background: rgba(255,255,255,0.1);
    border-radius: 50%;
    pointer-events: none;
}

.service-particle:nth-child(3n) {
    width: 4px;
    height: 4px;
    background: rgba(255,255,255,0.15);
}

.service-particle:nth-child(4n) {
    width: 8px;
    height: 8px;
    background: rgba(255,255,255,0.05);
}

@keyframes floatParticle {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translate(var(--moveX), var(--moveY));
        opacity: 0;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 992px) {
    .section-title {
        font-size: 2.5rem;
    }
    
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .services-section {
        padding: 80px 20px;
    }
    
    .section-title {
        font-size: 2.2rem;
    }
    
    .section-subtitle {
        font-size: 1rem;
    }
    
    .services-grid {
        grid-template-columns: 1fr;
    }
    
    .service-item {
        max-width: 500px;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }
    
    .service-content {
        padding: 20px;
    }
    
    .service-title {
        font-size: 1.3rem;
    }
    
    .service-description {
        font-size: 0.9rem;
    }
    
    .feature-tag {
        font-size: 0.75rem;
        padding: 3px 10px;
    }
} 