/* Global styles for the homepage */
.homepage {
    position: relative;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    overflow-x: hidden;
    color: #fff;
    background-color: #0a0a0a;
    width: 100%;
    max-width: 100vw;
}

/* Fix for horizontal scroll */
html, body {
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
    position: relative;
}

/* Custom cursor */
.cursor, .cursor-dot {
    position: fixed;
    pointer-events: none;
    z-index: 9999;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: transform 0.1s ease;
}

.cursor {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease;
}

.cursor-dot {
    width: 5px;
    height: 5px;
    background-color: white;
}

/* Hero Section */
.hero {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 40px 80px;
    overflow: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease, transform 1s ease;
    width: 100%;
    max-width: 100vw;
}

.hero.visible {
    opacity: 1;
    transform: translateY(0);
}

.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0,0,0,0.97) 0%, rgba(20,20,30,0.95) 100%);
}

.hero-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.particle {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: float-particle 15s infinite linear;
}

.particle:nth-child(odd) {
    width: 3px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.4);
}

.particle:nth-child(3n) {
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    animation-duration: 25s;
}

.particle:nth-child(1) { top: 10%; left: 20%; animation-delay: 0s; }
.particle:nth-child(2) { top: 20%; left: 80%; animation-delay: 1s; }
.particle:nth-child(3) { top: 30%; left: 45%; animation-delay: 2s; }
.particle:nth-child(4) { top: 40%; left: 15%; animation-delay: 3s; }
.particle:nth-child(5) { top: 50%; left: 85%; animation-delay: 4s; }
.particle:nth-child(6) { top: 60%; left: 35%; animation-delay: 5s; }
.particle:nth-child(7) { top: 70%; left: 70%; animation-delay: 6s; }
.particle:nth-child(8) { top: 80%; left: 25%; animation-delay: 7s; }
.particle:nth-child(9) { top: 85%; left: 90%; animation-delay: 8s; }
.particle:nth-child(10) { top: 15%; left: 60%; animation-delay: 9s; }
.particle:nth-child(11) { top: 25%; left: 30%; animation-delay: 10s; }
.particle:nth-child(12) { top: 35%; left: 75%; animation-delay: 11s; }
.particle:nth-child(13) { top: 45%; left: 40%; animation-delay: 12s; }
.particle:nth-child(14) { top: 55%; left: 10%; animation-delay: 13s; }
.particle:nth-child(15) { top: 65%; left: 50%; animation-delay: 14s; }
.particle:nth-child(16) { top: 75%; left: 65%; animation-delay: 15s; }
.particle:nth-child(17) { top: 85%; left: 55%; animation-delay: 16s; }
.particle:nth-child(18) { top: 5%; left: 45%; animation-delay: 17s; }
.particle:nth-child(19) { top: 95%; left: 35%; animation-delay: 18s; }
.particle:nth-child(20) { top: 55%; left: 25%; animation-delay: 19s; }

@keyframes float-particle {
    0% {
        transform: translateY(0) translateX(0);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform: translateY(-100px) translateX(100px);
        opacity: 0;
    }
}

.hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.hero-text {
    flex: 1;
    max-width: 620px;
}

.hero-title {
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.hero-title-line {
    display: block;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    transform: translateX(-10px);
    opacity: 0;
    animation: slide-in 0.8s forwards 0.3s;
}

.hero-title-line:nth-child(2) {
    animation-delay: 0.6s;
}

@keyframes slide-in {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.hero-subtitle {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 3rem;
    line-height: 1.5;
    opacity: 0;
    animation: fade-in 1s forwards 1s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hero-cta {
    display: flex;
    gap: 20px;
    opacity: 0;
    animation: fade-in 1s forwards 1.4s;
}

.primary-button {
    padding: 16px 36px;
    background: linear-gradient(90deg, #fff, #e0e0e0);
    color: #000;
    font-weight: 600;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.button-arrow {
    transition: transform 0.3s ease;
}

.primary-button:hover .button-arrow {
    transform: translateX(5px);
}

.secondary-button {
    padding: 15px 36px;
    background: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-button:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.5);
    transform: translateY(-2px);
}

.hero-visual {
    flex: 1;
    position: relative;
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    justify-content: center;
    overflow: hidden;
}

.spline-scene {
    width: 120% !important;
    height: 120% !important;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 1;
    pointer-events: none !important;
    touch-action: none !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    background: transparent !important;
}

/* Hide Spline watermark - more aggressive */
.spline-watermark,
[data-spline-watermark],
a[href*="spline"],
div[style*="spline"],
canvas + div,
.hero-visual > div:not(.spline-scene):not(.watermark-blocker),
div[style*="position: absolute"][style*="bottom: 0"][style*="right: 0"],
div[style*="pointer-events: none"][style*="position: absolute"],
div[style*="font-size: 12px"],
div[style*="z-index: 999"] {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
    width: 0 !important;
    height: 0 !important;
    max-width: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    z-index: -9999 !important;
    transform: scale(0) !important;
}

canvas {
    width: 100% !important;
    height: 100% !important;
    transform-origin: center center;
    pointer-events: none !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    background: transparent !important;
}

/* USP Section */
.usp-section {
    padding: 120px 40px;
    position: relative;
    overflow: hidden;
    background-color: #0a0a0a;
}

.section-title-container {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
}

.section-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.section-title-underline {
    height: 3px;
    width: 80px;
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%);
    margin: 0 auto;
}

.usp-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.usp-item {
    background: rgba(30, 30, 40, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    opacity: 0;
    transform: translateY(50px);
    position: relative;
    backdrop-filter: blur(10px);
}

.usp-item.visible {
    opacity: 1;
    transform: translateY(0);
}

.usp-item:hover {
    transform: translateY(-10px);
    background: rgba(40, 40, 50, 0.4);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.2);
}

.usp-icon {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
}

.usp-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    top: 0;
    left: 0;
}

.icon-ai {
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10H12V2z'/%3E%3Cpath d='M12 12h10' transform='rotate(-45 12 12)'/%3E%3Cpath d='M12 12h10' transform='rotate(45 12 12)'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-scalable {
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z'/%3E%3Cpath d='M12 22.5v-15'/%3E%3Cpath d='M21.95 12 12 5.25 2.05 12'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-support {
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48l2.83-2.83'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
}

.usp-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
}

.usp-description {
    font-size: 1rem;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 25px;
    flex-grow: 1;
}

.usp-link {
    margin-top: auto;
}

.usp-link a {
    color: #fff;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;
    position: relative;
}

.usp-link a::after {
    content: "→";
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.usp-link a:hover {
    color: rgba(255, 255, 255, 0.8);
}

.usp-link a:hover::after {
    transform: translateX(5px);
}

.usp-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.usp-accent {
    position: absolute;
    top: -50%;
    right: -10%;
    width: 800px;
    height: 800px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(60,60,80,0.15) 0%, rgba(10,10,10,0) 70%);
    filter: blur(50px);
    opacity: 0.6;
}

/* Interactive Section */
.interactive-section {
    padding: 120px 40px;
    position: relative;
    background: linear-gradient(0deg, #0a0a0a 0%, #111121 100%);
}

.interactive-container {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: center;
}

.interactive-text {
    max-width: 600px;
}

.interactive-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.7));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.interactive-description {
    font-size: 1.1rem;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.8);
}

.interactive-demo {
    position: relative;
}

.demo-box {
    position: relative;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(20, 20, 30, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.demo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s ease;
    z-index: 2;
}

.demo-overlay p {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
}

.demo-box:hover .demo-overlay {
    opacity: 0;
}

.demo-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
}

.demo-box:hover .demo-content {
    transform: rotateY(10deg) rotateX(5deg);
}

.demo-item {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    transform: translateZ(20px);
}

.demo-item:nth-child(1) {
    transform: translateZ(60px) translateX(-100px) translateY(-50px);
    width: 180px;
    height: 120px;
    background: linear-gradient(135deg, rgba(100,100,220,0.15) 0%, rgba(30,30,80,0.05) 100%);
}

.demo-item:nth-child(2) {
    transform: translateZ(40px) translateX(50px) translateY(20px);
    width: 140px;
    height: 190px;
    background: linear-gradient(135deg, rgba(220,100,100,0.15) 0%, rgba(80,30,30,0.05) 100%);
}

.demo-item:nth-child(3) {
    transform: translateZ(20px) translateX(-20px) translateY(80px);
    width: 200px;
    height: 100px;
    background: linear-gradient(135deg, rgba(100,220,100,0.15) 0%, rgba(30,80,30,0.05) 100%);
}

.demo-box:hover .demo-item:nth-child(1) {
    transform: translateZ(80px) translateX(-110px) translateY(-60px);
}

.demo-box:hover .demo-item:nth-child(2) {
    transform: translateZ(60px) translateX(60px) translateY(30px);
}

.demo-box:hover .demo-item:nth-child(3) {
    transform: translateZ(40px) translateX(-30px) translateY(90px);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .hero-title {
        font-size: 4rem;
    }
    
    .interactive-container {
        gap: 40px;
    }
    
    .hero-visual {
        height: 550px;
    }
}

@media (max-width: 992px) {
    .hero-content {
        flex-direction: column;
        text-align: center;
    }
    
    .hero-text {
        margin-bottom: 60px;
    }
    
    .hero-title {
        font-size: 3.5rem;
    }
    
    .hero-title-line {
        display: inline;
    }
    
    .hero-cta {
        justify-content: center;
    }
    
    .usp-container {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .interactive-container {
        grid-template-columns: 1fr;
        text-align: center;
    }
    
    .interactive-text {
        max-width: 100%;
        margin-bottom: 40px;
    }
    
    .hero-visual {
        height: 500px;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .hero {
        padding: 100px 20px 60px;
    }
    
    .hero-title {
        font-size: 3rem;
    }
    
    .usp-section,
    .interactive-section {
        padding: 80px 20px;
    }
    
    .section-title {
        font-size: 2.5rem;
    }
    
    .usp-container {
        grid-template-columns: 1fr;
    }
    
    .hero-visual {
        height: 450px;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 2.5rem;
    }
    
    .hero-subtitle {
        font-size: 1rem;
    }
    
    .hero-cta {
        flex-direction: column;
        width: 100%;
    }
    
    .primary-button,
    .secondary-button {
        width: 100%;
    }
    
    .section-title {
        font-size: 2rem;
    }
    
    .interactive-title {
        font-size: 2rem;
    }
    
    .demo-box {
        height: 320px;
    }
    
    .hero-visual {
        height: 400px;
    }
}

.watermark-blocker {
    display: none;
}

.watermark-blocker.bottom-right {
    display: none;
}

.watermark-blocker.bottom-center {
    display: none;
}

/* Remove the additional empty div that covers the bottom */
.hero-visual::after {
    display: none;
}

.scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    animation: fade-in 1s forwards 2s;
    z-index: 10;
}

.scroll-text {
    font-size: 0.875rem;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.7);
}

.scroll-icon {
    width: 24px;
    height: 36px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    position: relative;
    display: block;
}

.scroll-icon::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    transform: translateX(-50%);
    animation: scroll-down 2s infinite;
}

@keyframes scroll-down {
    0% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
    80% {
        opacity: 0;
        transform: translate(-50%, 15px);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, 15px);
    }
}